<template>
  <div>
    <AppHeader />
    <div class="flex-container">
      <div style="text-align: center; padding-top: 50px;">
        <div class="not-found">
          <img class="not-found__image notfound-image" src="@/assets/svg/element-404.svg" />
          <h2>
            {{ $t('BrowserWarning.PageNotFound') }}
          </h2>
          <router-link class="btn back-to-projects " :to="{ name: 'projects' }" type="button"> {{ $t('label.projects')}} </router-link>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import AppHeader from '@/components/_shared/header'

export default {
  name: '404',
  components: {
    AppHeader
  }
}
</script>

<style scoped>
.flex-container {
  display: flex;
  align-items: center;
  justify-content: center;
}

.back-to-projects {
  margin-top: 10px;
  background-color: #67ac45;
  color: white;
  font-size: 15px;
}
</style>
